<template>
  <portal
    to="breadcrumb"
    :disabled="!$route.meta.breadcrumbPortal"
  >
    <b-row
      v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="auto"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col class="d-flex align-items-center">
            <!--          <b-img-->
            <!--            v-if="pageImage || $route.meta.pageImage"-->
            <!--            :src="pageImage || $route.meta.pageImage"-->
            <!--            left-->
            <!--            rounded-->
            <!--            class="breadcrumb-image mr-1"-->
            <!--          />-->

            <h2 class="content-header-title pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <!--              <b-breadcrumb-item to="/">-->
                <!--                <feather-icon-->
                <!--                  icon="HomeIcon"-->
                <!--                  size="16"-->
                <!--                  class="align-text-top"-->
                <!--                />-->
                <!--              </b-breadcrumb-item>-->
                <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right ml-auto"
        cols="auto"
        md="3"
      >
        <portal-target
          name="breadcrumb-content-header-right"
          slim
        />
      </b-col>
    </b-row>
  </portal>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  computed: mapState('app', ['pageTitle', 'pageImage']),
}
</script>

<style lang="scss" scoped>
  .breadcrumb-image {
    max-width: 75px;
    max-height: 75px;
  }
  .list-image {
    max-width: 50px;
    max-height: 50px;
    margin-right: .2rem;
  }
  .content-header-title {
    font-size: 1.5rem;
    //letter-spacing: .8px;
  }
  .breadcrumb {
    font-size:0.85rem;
    align-items: center;
  }
  .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    height: 17px
  }
</style>
