<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Logo and Nav Menu -->
    <div class="align-items-center flex-grow-1 d-flex">
      <app-navbar-horizontal-layout-brand class="mr-2" />

      <div class="header-top header-navbar navbar-horizontal d-none d-xl-block">
        <horizontal-nav-menu-items :items="navMenuItems" />
      </div>
    </div>

    <dark-toggler class="d-none d-lg-block mr-2" />

    <!-- Organization & Account Dropdown -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        @hide="switchOrganizations = false"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav text-white">
            <p class="user-name font-weight-bolder mb-0">
              {{ organization.name }}
            </p>
            <span class="user-status text-muted">{{ user.full_name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light"
            :src="organization.logo_url"
          />
        </template>

        <!-- Organization -->
        <b-dropdown-group
          header="Organization"
          header-classes="text-muted"
        >
          <b-dropdown-item
            :to="{ name: 'organization.information' }"
            link-class="d-flex align-items-center"
            active-class="active"
          >
            <font-awesome-icon
              icon="fa-light fa-circle-info"
              fixed-width
              class="mr-50"
            />
            <span>Info</span>
          </b-dropdown-item>

          <!--          <b-dropdown-item-->
          <!--            :to="{ name: 'organization.venues' }"-->
          <!--            link-class="d-flex align-items-center"-->
          <!--            active-class="active"-->
          <!--          >-->
          <!--            <font-awesome-icon-->
          <!--              icon="fa-light fa-warehouse-full"-->
          <!--              fixed-width-->
          <!--              class="mr-50"-->
          <!--            />-->
          <!--            <span>Venues / Facilities</span>-->
          <!--          </b-dropdown-item>-->

          <!--          <b-dropdown-item link-class="d-flex align-items-center">-->
          <!--            <font-awesome-icon-->
          <!--              icon="fa-light fa-users"-->
          <!--              fixed-width-->
          <!--              class="mr-50"-->
          <!--            />-->
          <!--            <span>Users</span>-->
          <!--          </b-dropdown-item>-->

          <a
            v-if="organizations.length > 1 || userUsedMasterPassword()"
            class="dropdown-item"
            href="#"
            @click.stop="switchOrganizations = !switchOrganizations"
          >
            <font-awesome-icon
              icon="fa-light fa-sitemap"
              fixed-width
              class="mr-50"
            />
            <span>Switch Organizations</span>
          </a>

          <template v-if="switchOrganizations">
            <b-dropdown-divider />

            <b-dropdown-item
              variant="primary"
              :to="{ name: 'organization.create' }"
            >
              <feather-icon
                size="16"
                icon="PlusIcon"
                class="mr-50"
              />
              <span>Add New</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-for="organization in $store.state.account.organizations"
              :key="organization.id"
              :active="organization.id === $store.state.account.activeOrganization"
              @click="updateOrganization(organization)"
            >
              {{ organization.name }}
            </b-dropdown-item>
            <b-dropdown-divider />
          </template>
        </b-dropdown-group>

        <!-- Account -->
        <b-dropdown-group
          header="Account"
          header-classes="text-muted"
        >
          <!--          <b-dropdown-item link-class="d-flex align-items-center">-->
          <!--            <feather-icon-->
          <!--              size="16"-->
          <!--              icon="SettingsIcon"-->
          <!--              class="mr-50"-->
          <!--            />-->
          <!--            <span>Settings</span>-->
          <!--          </b-dropdown-item>-->

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Logout</span>
          </b-dropdown-item>
        </b-dropdown-group>
      </b-nav-item-dropdown>

    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BDropdownGroup, BAvatar, BLink,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import HorizontalNavMenuItems from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import navMenuItems from '@/navigation/horizontal'

import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { userUsedMasterPassword } from '@/auth/utils'

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownGroup,
    BAvatar,
    BLink,
    // Navbar Components
    DarkToggler,
    AppNavbarHorizontalLayoutBrand,
    HorizontalNavMenuItems,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData')),
      switchOrganizations: false,
      avatarText,
      navMenuItems,
    }
  },
  computed: {
    ...mapGetters('account', ['organization']),
    ...mapState('account', ['organizations']),
  },
  methods: {
    ...mapMutations('account', ['UPDATE_ACTIVE_ORGANIZATION']),
    userUsedMasterPassword,
    updateOrganization(organization) {
      this.UPDATE_ACTIVE_ORGANIZATION(organization.id)

      // Reload page with new organization selected.
      window.location.replace(window.location.origin)
    },
    logout() {
      this.$http.post('/auth/logout', null, {
        baseURL: process.env.VUE_APP_BASE_URL,
      }).then(() => {
        // Remove auth tokens from localStorage
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove account info from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('masterPass')
        localStorage.removeItem('activeOrganization')

        // Reset ability
        // this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.replace({ name: 'auth-login' })
      })
    },
  },
}
</script>
