<template>
  <portal-target
    name="footer-content"
  />
</template>

<script>

export default {

}
</script>
