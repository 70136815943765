export default [
  {
    title: 'Leagues',
    route: 'leagues',
    icon: 'fa-light fa-calendar-star',
  },
  {
    title: 'Tournaments',
    route: '',
    icon: 'fa-light fa-trophy-star',
  },
  {
    title: 'Programs',
    route: '',
    icon: 'fa-light fa-shirt-running',
  },
  // {
  //   title: 'Your Teams',
  //   route: 'teams',
  //   icon: 'fa-light fa-whistle',
  // },
  // {
  //   title: 'Your Players',
  //   route: 'players',
  //   icon: 'fa-light fa-shirt-running',
  // },
  // {
  //   title: 'Venues / Facilities',
  //   route: 'organization.venues',
  //   icon: 'fa-light fa-warehouse-full',
  // },
  // {
  //   title: 'Billing',
  //   route: '',
  //   icon: '',
  // },
  // {
  //   title: 'My Account',
  //   route: '',
  //   icon: '',
  // },
]
